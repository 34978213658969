export const environment = {
	production: false,
	env: 'dev',
	apiUrls: {
		api: 'https://stage-api.kayayoafrica.com/api'
		// api: 'http://10.10.1.189:3033/api'
	},
	google: {
		// apiKey: "AIzaSyD8vWFig7Ep4lvocL025Xyg--md-F3qk2k",
		apiKey: 'AIzaSyCPaWZ82O2KbXCsZwnZXq0Qe_kKu-SL_WA'
	},
	firebaseConfig: {
		apiKey: 'AIzaSyBVblQab4dsVBJ3R70tDsDUOXmKdkPyW-o',
		authDomain: 'kayayo-dev-app.firebaseapp.com',
		projectId: 'kayayo-dev-app',
		storageBucket: 'kayayo-dev-app.appspot.com',
		messagingSenderId: '55389766054',
		appId: '1:55389766054:web:d5d6293bc186ce5ec70f67',
		// measurementId: 'G-MJSBVE65MP'
		// vapidKey:
		//   'BJaidX-vgqBbSZzX5mnlaaSYmAfF6vt7hvhvhGDlezMlihKjNPRS5GNdWzqXUT-VUqtDqaLwdfBo6KW_czfaVrk',
	}
};
